const move = () => {
    let i = 0
  if (i == 0) {
    i = 1;
    const elem = document.getElementById("__bar");
    let width = 1;
    let id = setInterval(frame, 10);
    function frame() {
      if (width >= 100) {
        clearInterval(id);
        i = 0;
      } else {
        width++;
        console.log(width)
        elem.style.width = width + "%";
      }
    }
  }
}

export const onInitialClientRender = () => {
    move();
    setTimeout(function() {
        document.getElementById("___loader").style.display = "none"
    }, 1000)
}